<template>
  <section class="section">
    <h4 class="title has-text-centered is-4">Generators</h4>
    <div
      v-for="generator in generators"
      :key="generator.id"
      style="padding-bottom:20px; margin-bottom: 20px; border-bottom: 1px solid #aaa;"
    >
      <h5 class="title is-5"><img :src="generatorImageSource(generator.id)" class="furniture-image" /> {{ generator.name }}</h5>

      <div v-if="typeof items[generator.id] !== 'undefined'">
        <table class="table">
          <tr v-for="(levelItems, level) in items[generator.id]" :key="level" >
            <td style="vertical-align: middle;"><strong>LEVEL {{ level + 1 }}</strong></td>
            <td v-for="(levelItem, index) in levelItems" :key="index" style="vertical-align: middle;padding: 14px;">
              <img :src="itemImageSource(levelItem.id)" class="furniture-image" />
              <br />
              {{ levelItem.name }}
            </td>
          </tr>
        </table>
      </div>
    </div>

  </section>
</template>

<script>
import { mapState } from 'vuex';
import { getCraftingImageUrl } from '../../services/encyclopedia';
import { Crafting } from '../../services/api';

export default {
  name: 'Generators',
  computed: {
    ...mapState('crafting', ['loadingGenerators', 'generators']),

  },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.$store.dispatch('crafting/loadGenerators')
      .then(() => {
        Crafting.getGeneratorItems().then((data) => {
          const items = [];
          data.forEach((item) => {
            if (!items[item.generator_id]) {
              items[item.generator_id] = [];
            }

            if (!items[item.generator_id][item.generator_level - 1]) {
              items[item.generator_id][item.generator_level - 1] = [];
            }

            items[item.generator_id][item.generator_level - 1].push(item);
          });

          this.items = items;

          console.log(items);
        });
      });
  },
  methods: {
    generatorImageSource(id) {
      return getCraftingImageUrl('generators', id);
    },
    itemImageSource(id) {
      return getCraftingImageUrl('items', id);
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
.furniture-image {
  max-height: 40px;
  max-width: 40px;
}
</style>
